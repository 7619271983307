import { EventEmitter, Injectable } from '@angular/core';

import { config, languages, shortcuts, designTranslation } from 'app/config';
import { v4 as uuidv4 } from 'uuid';
import {BehaviorSubject, lastValueFrom, Observable} from "rxjs";

import { HttpService } from "./http.service";
import { Project } from "../models/project";
import { Language } from "../models/language";
import { Shortcut } from "../models/shortcut";
import { Video } from "../models/video";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: 'root',
})

export class ConfigService {
    config!: Project
    videos: Video[] = []
    configOld: any = config
    languages: Language[] = languages

    private orchestratorUrl: string = environment.orchestratorUrl

    // config translate components
    shortcuts: Shortcut[] = []
    shortcutIcons: string[] = [
        "assets/icons/contactUs.svg",
        "assets/icons/hoursOfOperation.svg",
        "assets/icons/symplicityGuaranteed.svg"
    ]

    maleicon = '/assets/icons/male.svg';
    femaleicon = '/assets/icons/women.svg';

    private projectId: string | null = null;
    private isConversationTabActive: boolean = false;

    private apiHost: string = environment.apiUrl
    private wssScheme: string = environment.wssScheme
    private httpScheme: string = environment.httpScheme

    private isFirstOpen: boolean = false;
    private isClickedMessageFeedback: boolean = false;

    scrollToBottomEvent: EventEmitter<any> = new EventEmitter();
    blurEfectEvent: EventEmitter<any> = new EventEmitter();
    avatarBackground = new BehaviorSubject<any>({});
    avatarBackground$ = this.avatarBackground.asObservable();
    langVideos = new BehaviorSubject<any>({});
    langVideos$ = this.langVideos.asObservable();
    avatarIcon = new BehaviorSubject<any>({});
    avatarIcon$ = this.avatarIcon.asObservable();
    dIdVideo = new BehaviorSubject<any>(null);
    dIdVideo$ = this.dIdVideo.asObservable();
    voiceId: string = '';

    constructor(private http: HttpService) {}

    // getters
    getOrchestrationHost () {
        return this.orchestratorUrl;
    }

    getAgentId(): string {
        return this.config.agent_id;
    }

    getHost () {
        return this.apiHost;
    }

    getWssScheme () {
        return this.wssScheme;
    }

    getHttpScheme () {
        return this.httpScheme;
    }

    getProjectId(): string | null {
        return this.projectId;
    }

    getAnonymousId(): string {
        const declare = (key: string): string => {
            let id = localStorage.getItem(key) ?? uuidv4();
            localStorage.setItem(key, id);
            return id;
        }

        return declare("anonymousId");
    }

    getDesignId(): string {
        //return this.config.design.id;
        return '7313363a-d0d2-49e1-a306-78002ef9d1bc';
    }

    getFromObject(needle: string, haystack: { [key: string]: any }): any {
        const keys = needle.split('.');
        const item = haystack[keys[0]];
        if (item === undefined || keys.length < 2) {
            return item;
        } else {
            keys.shift();
            return this.getFromObject(keys.join('.'), item);
        }
    }

    getVideos(): Video[] {
        return this.videos;
    }

    getLangVideos(): Observable<any> {
        return this.langVideos;
    }

    getPresenter(): Observable<any> {
        return this.avatarBackground;
    }

    getFirstTime () {
        return this.isFirstOpen;
    }

    getAvatarIcon(): Observable<any> {
        return this.avatarIcon;
    }

    isComponentEnabled(uuid: string): boolean {
        return (
            this.getFromObject('configOld.configAvatar.components', this)?.find(
                (c: { id: string; enabled: boolean }) => c.id === uuid && c.enabled,
            ) ?? false
        );
    }

    getComponentStyle(uuid: string) {
        return (
            this.getFromObject('configOld.configAvatar.design.components', this)?.find(
                (c: { id: string }) => c.id === uuid,
            ) ?? {}
        );
    }

    getLanguages() {
        return this.config.project_languages;
    }

    getLanguage(locale: string) {
        return this.getLanguages().find(l => l.locale === locale)
    }

    getProjectName(): string {
        return this.config.project_name;
    }

    getAgentName(): string {
        return this.config.agent_name;
    }

    getConversationTabStatus() {
        return this.isConversationTabActive;
    }

    getShortcuts(language: string) {
        let s = this.config.project_shortcuts.find(s => s.locale === language);
        if (!s) return [];

        return s.shortcuts.map((shortcut, index) => ({
            iconPath: this.shortcutIcons[index],
            text: shortcut.name,
        }));
    }

    getClickedMessageFeedbackStatus() {
        return this.isClickedMessageFeedback;
    }

    getDirectionFromLanguage(locale: string) {
        const language = this.getLanguage(locale)
        return language ? language.direction : 'ltr'
    }

    getDIdVideo(): Observable<any> {
        return this.dIdVideo;
    }

    getDesignType() {
        return environment.design;
    }

    getVoiceId() {
        return this.voiceId;
    }

    // setters
    setProjectId (id: string | null) {
        this.projectId = id;
    }

    setProjectConfig (config: any) {
        console.log(config)

        const {
            project_id,
            project_name,
            agent_name,
            agent_id,
            design,
            project_languages,
            project_shortcuts
        } = config;

        this.config = {
            project_id: project_id,
            project_name: project_name,
            agent_name: agent_name,
            agent_id: agent_id,
            design: design,
            project_languages: project_languages,
            project_shortcuts: project_shortcuts,
        }

        this.setSelectedLanguage("en-US");
    }

    setSelectedLanguage (locale: string) {
        this.config.project_languages = this.config.project_languages.map(language => {
            return {
                id: language.id,
                name: language.name,
                icon: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.icon ?? ""; })(),
                locale: language.locale,
                direction: ((): string => { const l = this.languages.find((l: any) => l.locale === language.locale); return l?.direction ?? "ltr"; })(),
                selected: language.locale === locale,
                created_at: language.created_at,
                updated_at: language.updated_at,
                presenter_idle_video_url: language.presenter_idle_video_url,
                presenter_url: language.presenter_url,
                presenter_welcome_video_url: language.presenter_welcome_video_url,
                voices: language.voices
            }
        })
    }
    setConversationTabStatusMode (mode: boolean) {
        this.isConversationTabActive = mode;
    }

    setFirstOpen () {
        if (!this.isFirstOpen)
            this.isFirstOpen = true;
    }

    setClickedMessageFeedbackStatus(mode: boolean) {
        this.isClickedMessageFeedback = mode;
    }

    setPresenter(language: string): void {
        this.avatarBackground.next(this.getLanguage(language)?.presenter_url);
    }

    setAvatarIcon(gender: string): void {
        const icon = (gender === 'male') ? this.maleicon : (gender === 'female') ? this.femaleicon : null;
        this.avatarIcon.next(icon);
    }

    setVideos (video: Video[]) {
        if (!Array.isArray(video)) video = [video];
        this.videos.push(...video);
    }

    setLangVideos(language: string): void {
        this.langVideos.next(this.videos.filter(video => video.language === language))
    }

    isSupportedLanguageIcon(locale: string): boolean {
        return !!this.languages.find(l => l.locale === locale);
    }

    setUpConfigVideos (language: string): Video[] {
        return [
            {
                type: 'idle',
                blob: null,
                source: this.getLanguage(language)?.presenter_idle_video_url!,
                language: language
            },
            {
                type: 'welcome',
                blob: null,
                source: this.getLanguage(language)?.presenter_welcome_video_url!,
                language: language
            }
        ];
    }

    setDIdVideo(dIdSrcObject: any) {
        this.dIdVideo.next(dIdSrcObject);
    }

    setVoiceId(voiceName: string) {
        this.voiceId = voiceName;
    }

    async createAiAgent (user: any) {
        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.getHttpScheme()}://${this.getHost()}/agent/${this.getProjectId()}/create`)
                    .setMethod("POST")
                    .setContent({
                        "id":1,
                        "name":`${user.first_name} ${user.last_name}`,
                        "nationality": user.nationality,
                        "current_residency": user.current_residency,
                        "original_residency": user.original_residency ?? "",
                        "age":user.age,
                        "gender":user.gender,
                        "insurance_policy":user.policy
                    })
                    .setHeaders({
                        "content-type": "application/json",
                        "Authorization": `Bearer ${this.getAnonymousId()}`
                    })
                    .create()
            );
        } catch (e: any) {
            return {
                status: 500,
                body: {}
            }
        }
    }

    async fetchConfiguration () {
        try {
            return await lastValueFrom(
                this.http
                    .setHost(`${this.getHttpScheme()}://${this.getHost()}/agent/${this.getProjectId()}/${this.getDesignType()}/config`)
                    .setMethod("GET")
                    .setHeaders({
                        "content-type": "application/json",
                        "Authorization": `Bearer ${this.getAnonymousId()}`
                    })
                    .create()
            );
        } catch (e: any) {
            return {
                status: 500,
                body: {}
            }
        }
    }

    // TODO Remove no-cors mode
    async fetchVideos(videos: Video[]) {
        return await Promise.all(videos.map(async video => {
            const request = await fetch(video.source, { mode: 'no-cors' });
            video.blob = await request.blob();
            return video;
        }));
    }
}
