export const config = {
    environment: 'production',
    account: {
        projectId: 'f0226614-267f-4a27-869a-22aef3fbb45b',
        projectName: "Ahoj meine liebe",
    },
    configAvatar: {
        components: [
            {
                id: '7a55f27d-e3c2-4188-a515-9e858181ddc0',
                name: 'shortcuts',
                enabled: true,
            },
            {
                id: '38f92e2d-1652-409c-af8c-fce3c94cff33',
                name: 'avatar',
                enabled: true,
            },
            {
                id: '5ca2e99b-5490-40f9-b747-df1637dd8512',
                name: 'avatarInit',
                enabled: true,
            },
            {
                id: '675c5caa-ab5f-4131-af81-eb29dd131d75',
                name: 'topSection',
                enabled: true,
            },
            {
                id: 'a54a1396-6bad-4fd6-a8c3-46515242bcee',
                name: 'avatar-controls',
                enabled: true,
            },
            {
                id: '82e84f05-beb5-4d90-918f-ac1c5905e218',
                name: 'languages',
                enabled: true,
            },
            {
                id: '06f24980-d290-445e-b1b9-5976239dbe4c',
                name: 'loading',
                enabled: true,
            },
            {
                id: '06ebd319-1378-4f59-bebf-e0271b5729b4',
                name: 'thumbs',
                enabled: true,
            },
            {
                id: '55e85847-e961-482a-b735-888c2c17eb21',
                name: 'feedback',
                enabled: true,
            },
            {
                id: 'b36faef7-619f-44a0-90e3-6168aea42a11',
                name: 'dragDrop',
                enabled: true,
            },
            {
                id: '8c6d7946-6dd3-4cc1-8372-a7695db0f84c',
                name: 'avatar-conversation-board',
                enabled: true,
            },
            {
                id: 'e99a2fff-624a-4475-9530-139b5b16191c',
                name: 'message',
                enabled: true,
            },
        ],
        design: {
            //8725ba88-54a8-4888-8fb6-af6f4c9012ff - default design
            //7313363a-d0d2-49e1-a306-78002ef9d1bc - default new design
            //4e9761a1-c136-4125-8706-d5de851a2e6f - predefined video design
            //3d41f6bc-ad1e-47fe-822d-c0653c22a3f8 - minimalistic design
            id: '7313363a-d0d2-49e1-a306-78002ef9d1bc',
            name: 'Default design',
            components: [
                {
                    id: '7a55f27d-e3c2-4188-a515-9e858181ddc0',
                    properties: {},
                },
                {
                    id: '38f92e2d-1652-409c-af8c-fce3c94cff33',
                    properties: {},
                },
                {
                    id: '5ca2e99b-5490-40f9-b747-df1637dd8512',
                    properties: {},
                },
                {
                    id: '675c5caa-ab5f-4131-af81-eb29dd131d75',
                    properties: {},
                },
                {
                    id: 'a54a1396-6bad-4fd6-a8c3-46515242bcee',
                    properties: {},
                },
                {
                    id: '82e84f05-beb5-4d90-918f-ac1c5905e218',
                    properties: {},
                },
                {
                    id: '06f24980-d290-445e-b1b9-5976239dbe4c',
                    properties: {},
                },
                {
                    id: '7ddc9fbc-abc1-4aac-b3d9-23825146b524',
                    properties: {},
                },
                {
                    id: '19164eca-a653-4799-a2e5-9948f832fe6f',
                    properties: {},
                },
                {
                    id: '451523db-84b5-4ece-9024-32020c634c16',
                    properties: {},
                },
                {
                    id: 'b36faef7-619f-44a0-90e3-6168aea42a11',
                    properties: {},
                },
                {
                    id: '55e85847-e961-482a-b735-888c2c17eb21',
                    properties: {},
                },
                {
                    id: '06ebd319-1378-4f59-bebf-e0271b5729b4',
                    properties: {},
                },
                {
                    id: '8c6d7946-6dd3-4cc1-8372-a7695db0f84c',
                    properties: {},
                },
                {
                    id: 'e99a2fff-624a-4475-9530-139b5b16191c',
                    properties: {},
                },
            ],
        },
    },
};

export const languages = [
    {
        name: 'عربي',
        icon: '../assets/languages/arabic.svg',
        locale: 'ar-SA',
        direction: 'rtl',
        selected: false,
    },
    {
        name: '中國人',
        icon: '../assets/languages/chinese.svg',
        locale: 'zh-CN',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'English',
        icon: '../assets/languages/english.svg',
        locale: 'en-US',
        direction: 'ltr',
        selected: true,
    },
    {
        name: 'Français',
        icon: '../assets/languages/french.svg',
        locale: 'fr-FR',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'Deutsch',
        icon: '../assets/languages/german.svg',
        locale: 'de-DE',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'Русский',
        icon: '../assets/languages/russian.svg',
        locale: 'ru-RU',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'Español',
        icon: '../assets/languages/spanish.svg',
        locale: 'es-ES',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'Türkçe',
        icon: '../assets/languages/turkish.svg',
        locale: 'tr-TR',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'Srpski',
        icon: '../assets/languages/serbian.svg',
        locale: 'sr-RS',
        direction: 'ltr',
        selected: false,
    },
    {
        name: 'Српски',
        icon: '../assets/languages/serbian.svg',
        locale: 'sr-Cyrl-RS',
        direction: 'ltr',
        selected: false,
    }
];

export const shortcuts = [
    // Arabic
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'ar-SA',
        text: 'اتصل بنا'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'ar-SA',
        text: 'ساعات العملية'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'ar-SA',
        text: 'التماثل مضمون'
    },
    // Chinese
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'zh-CN',
        text: '聯絡我們'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'zh-CN',
        text: '營業時間'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'zh-CN',
        text: '保證簡單性'
    },
    // English
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'en-US',
        text: 'How much people China has?'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'en-US',
        text: 'What you can tell me about roses flowers?'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'en-US',
        text: 'Which country has the most lakes?'
    },
    // French
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'fr-FR',
        text: 'Contactez-nous'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'fr-FR',
        text: 'Heures d\'ouverture'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'fr-FR',
        text: 'Symplicité garantie'
    },
    // German
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'de-DE',
        text: 'Kontaktiere uns'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'de-DE',
        text: 'Öffnungszeiten'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'de-DE',
        text: 'Einfachheit garantiert'
    },
    // Russian
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'ru-RU',
        text: 'Связаться с нами'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'ru-RU',
        text: 'Часы работы'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'ru-RU',
        text: 'Простота гарантирована'
    },
    // Spanish
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'es-ES',
        text: 'Contacta con nosotros'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'es-ES',
        text: 'Horas de operación'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'es-ES',
        text: 'Simplicidad garantizada'
    },
    // Turkish
    {
        iconPath: 'assets/icons/contactUs.svg',
        language: 'tr-TR',
        text: 'Bize Ulaşın'
    },
    {
        iconPath: 'assets/icons/hoursOfOperation.svg',
        language: 'tr-TR',
        text: 'Çalışma saatleri'
    },
    {
        iconPath: 'assets/icons/symplicityGuaranteed.svg',
        language: 'tr-TR',
        text: 'Sadelik Garantili'
    },
];

export const designTranslation = [
    {
        locale: "ar-SA",
        typography: {
            // welcome message
            welcome: "مرحبا! كيف يمكنني مساعدتك؟",
            lostNet: "عذرًا، يبدو أنني معطل مؤقتًا. يرجى المحاولة مرة أخرى لاحقًا.",

            // landing page translation
            loginButton: "تسجيل الدخول",
            startedButton: "البدء",
            navigationList: [ "هومجيتس", "معلومات عنا", "منتجات", "خدمات", "مدونة", "يساعد" ],
            welcomeHeader: "حيث يلتقي الابتكار بالتفاعل الذكي",
            welcomeDescription: `نحن ملتزمون بشدة برفع أداء شركائنا. نحن نحسن الكفاءة،
         زيادة القيمة، وتنمية الشراكات الحقيقية. نحن نخلق النجاح المتبادل وندعم المواهب المحلية
         وأحدث التقنيات.`,
            subscribeButton: "يشترك",

            // loading translation
            loadingText: "أنا في رحلة بحث عن إجابتك..",

            // language names translation
            /*languageNames: { "ar-SA": "عربي", "zh-CN": "صيني", "en-US": "إنجليزي", "fr-FR": "فرنسي",
                "de-DE": "ألمانية", "ru-RU": "الروسية", "es-ES": "اللغة", "tr-TR": "التركية" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: [ "مرحبًا، أنا هنا للمساعدة!", "انقر هنا لبدء الدردشة." ],

            // avatar controls translation
            currentInputPlaceholder: "إسألني سؤال...",

            // drag drop translation
            dragDropText: "قم بسحب وإفلات الملف هنا أو",
            buttonBrowseText: "تصفح ملفات",
            supportedFormatsText: "التنسيقات المدعومة: jpg، png، txt، pdf.",
            maxFileSizeText: "الحد الأقصى لحجم الملف: 6 ميجابايت.",
            buttonText: "يُقدِّم",
            attachmentText: "تحميل المرفق",

            // feedback translation
            placeholderText: 'اكتب ملاحظاتك الإضافية...',
            feedbackText: 'تقديم تعليقات إضافية',
            harmfulCheckboxText: 'هذا ضار/غير آمن',
            helpfulCheckboxText: 'هذا ليس مفيدا',
            trueCheckBoxText: 'هذا ليس صحيحا'
        }
    },
    {
        locale: "zh-CN",
        typography: {
            // welcome message
            welcome: "你好！我能帮你什么忙吗？",
            lostNet: "抱歉，看来我暂时断开连接了。请稍后再试。",

            // landing page translation
            loginButton: "登入",
            startedButton: "開始使用",
            navigationList: ["家園", "關於我們", "產品", "服務", "部落格", "幫助"],
            welcomeHeader: "創新與智能交互的邂逅",
            welcomeDescription: `我們熱忱致力於提升合作夥伴的績效。 我們提高效率，
             增加價值，發展真正的合作關係。 我們創造共同的成功並支持本地人才
             和最新技術。`,
            subscribeButton: "訂閱",

            // loading translation
            loadingText: "我正在尋找你的答案......",

            // language names translation
            /*languageNames: { "ar-SA": "阿拉伯", "zh-CN": "中國人", "en-US": "英語", "fr-FR": "法語",
                "de-DE": "德文", "ru-RU": "俄文", "es-ES": "西班牙語", "tr-TR": "土耳其" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["你好，我是來幫忙的！", "點擊此處開始聊天。"],

            // avatar controls translation
            currentInputPlaceholder: "問我問題...",

            // drag drop translation
            dragDropText: "將文件拖放到此處或",
            buttonBrowseText: "瀏覽文件",
            supportedFormatsText: "支援的格式：jpg、png、txt、pdf。",
            maxFileSizeText: "最大檔案大小：6MB。",
            buttonText: "提交",
            attachmentText: "上傳附件",

            // feedback translation
            placeholderText: '輸入您的附加回饋...',
            feedbackText: '提供額外的回饋',
            harmfulCheckboxText: '這是有害的/不安全的',
            helpfulCheckboxText: '這沒有幫助',
            trueCheckBoxText: '這不是真的'
        }
    },
    {
        locale: "en-US",
        typography: {
            // welcome message
            welcome: "Hello! How can I help you?",
            lostNet: "Apologies, it appears I'm momentarily disconnected. Please try again later.",

            // landing page translation
            loginButton: "Login",
            startedButton: "Get started",
            navigationList: ["Homegits", "About Us", "Products", "Services", "Blog", "Help"],
            welcomeHeader: "Where Innovation Meets Intelligent Interaction",
            welcomeDescription: `We are passionately committed to elevating our partners’ performance. We improve efficiency,
            increase value, and grow genuine partnerships. We create mutual success and champion both local talent
            and the latest technologies.`,
            subscribeButton: "Subscribe",

            // loading translation
            loadingText: "I'm on a quest to find your answer...",

            // language names translation
            /*languageNames: { "ar-SA": "Arabic", "zh-CN": "Chinese", "en-US": "English", "fr-FR": "French",
                "de-DE": "German", "ru-RU": "Russian", "es-ES": "Spanish", "tr-TR": "Turkish" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Hello, I'm here to help!", "Click here to start the chat."],

            // avatar controls translation
            currentInputPlaceholder: "Ask me a question...",

            // drag drop translation
            dragDropText: "Drag & drop file here or",
            buttonBrowseText: "Browse files",
            supportedFormatsText: "Supported formats: jpg, png, txt, pdf.",
            maxFileSizeText: "Max file size: 6MB.",
            buttonText: "Submit",
            attachmentText: "Upload Attachment",

            // feedback translation
            placeholderText: 'Type your additional feedback...',
            feedbackText: 'Provide additional feedback',
            harmfulCheckboxText: 'This is harmful/unsafe',
            helpfulCheckboxText: 'This isn’t helpful',
            trueCheckBoxText: 'This isn’t true'
        }
    },
    {
        locale: "fr-FR",
        typography: {
            // welcome message
            welcome: "Bonjour! Comment puis-je vous aider?",
            lostNet: "Désolé, il semble que je sois momentanément déconnecté. Veuillez réessayer plus tard.",

            // landing page translation
            loginButton: "Se connecter",
            startedButton: "Commencer",
            navigationList: ["Accueilgits", "À propos de nous", "Des produits", "Prestations de service", "Blog", "Aide"],
            welcomeHeader: "Où l'innovation rencontre l'interaction intelligente",
            welcomeDescription: `Nous nous engageons avec passion à améliorer les performances de nos partenaires. Nous améliorons l'efficacité,
             augmenter la valeur et développer de véritables partenariats. Nous créons un succès mutuel et défendons les talents locaux
             et les dernières technologies.`,
            subscribeButton: "S'abonner",

            // loading translation
            loadingText: "Je suis à la recherche de votre réponse...",

            // language names translation
            /*languageNames: { "ar-SA": "Arabe", "zh-CN": "Chinoise", "en-US": "Anglaise", "fr-FR": "Français",
                "de-DE": "Allemande", "ru-RU": "Russe", "es-ES": "Espagnole", "tr-TR": "Turque" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Bonjour, je suis là pour vous aider !", "Cliquez ici pour démarrer le chat."],

            // avatar controls translation
            currentInputPlaceholder: "Pose-moi une question...",

            // drag drop translation
            dragDropText: "Glissez et déposez le fichier ici ou",
            buttonBrowseText: "Parcourir les fichiers",
            supportedFormatsText: "Formats pris en charge : jpg, png, txt, pdf.",
            maxFileSizeText: "Taille maximale du fichier: 6 Mo.",
            buttonText: "Soumettre",
            attachmentText: "Télécharger la pièce jointe",

            // feedback translation
            placeholderText: 'Tapez vos commentaires supplémentaires...',
            feedbackText: 'Fournir des commentaires supplémentaires',
            harmfulCheckboxText: 'Ceci est dangereux/dangereux',
            helpfulCheckboxText: 'Ce n\'est pas utile',
            trueCheckBoxText: 'Ce n\'est pas vrai'
        }
    },
    {
        locale: "de-DE",
        typography: {
            // welcome message
            welcome: "Hallo! Wie kann ich Ihnen helfen?",
            lostNet: "Entschuldigung, es scheint, dass ich momentan getrennt bin. Bitte versuchen Sie es später erneut.",

            // landing page translation
            loginButton: "Anmeldung",
            startedButton: "Loslegen",
            navigationList: ["Homegits", "Über uns", "Produkte", "Dienstleistungen", "Blog", "Helfen"],
            welcomeHeader: "Wo Innovation auf intelligente Interaktion trifft",
            welcomeDescription: `Wir engagieren uns mit Leidenschaft dafür, die Leistung unserer Partner zu steigern. Wir verbessern die Effizienz,
             Wert steigern und echte Partnerschaften aufbauen. Wir schaffen gemeinsamen Erfolg und fördern beide lokalen Talente
             und die neuesten Technologien.`,
            subscribeButton: "Abonnieren",

            // loading translation
            loadingText: "Ich bin auf der Suche nach Ihrer Antwort ...",

            // language names translation
            /*languageNames: { "ar-SA": "Arabisch", "zh-CN": "Chinesisch", "en-US": "Englisch", "fr-FR": "Französisch",
                "de-DE": "Deutsch", "ru-RU": "Russisch", "es-ES": "Spanisch", "tr-TR": "Türkisch" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Hallo, ich bin hier, um zu helfen!", "Klicken Sie hier, um den Chat zu starten."],

            // avatar controls translation
            currentInputPlaceholder: "Stell mir eine Frage...",

            // drag drop translation
            dragDropText: "Datei per Drag & Drop hierher ziehen oder",
            buttonBrowseText: "Dateien durchsuchen",
            supportedFormatsText: "Unterstützte Formate: jpg, png, txt, pdf.",
            maxFileSizeText: "Maximale Dateigröße: 6 MB.",
            buttonText: "Einreichen",
            attachmentText: "Anhang hochladen",

            // feedback translation
            placeholderText: 'Geben Sie Ihr zusätzliches Feedback ein...',
            feedbackText: 'Geben Sie zusätzliches Feedback',
            harmfulCheckboxText: 'Das ist schädlich/unsicher',
            helpfulCheckboxText: 'Das ist nicht hilfreich',
            trueCheckBoxText: 'Das ist nicht wahr'
        }
    },
    {
        locale: "ru-RU",
        typography: {
            // welcome message
            welcome: "Привет! Чем я могу вам помочь?",
            lostNet: "Извините, похоже, я на данный момент отключен. Пожалуйста, попробуйте снова позже.",

            // landing page translation
            loginButton: "Авторизоваться",
            startedButton: "Начать",
            navigationList: ["Хоумгитс", "О нас", "Продукты", "Услуги", "Блог", "Помощь"],
            welcomeHeader: "Где инновации встречаются с интеллектуальным взаимодействием",
            welcomeDescription: `Мы искренне стремимся повышать эффективность наших партнеров. Мы повышаем эффективность,
             повышайте ценность и развивайте настоящие партнерские отношения. Мы добиваемся взаимного успеха и поддерживаем как местные таланты, так и
             и новейшие технологии.`,
            subscribeButton: "Подписаться",

            // loading translation
            loadingText: "Я ищу твой ответ...",

            // language names translation
            /*languageNames: { "ar-SA": "Арабский", "zh-CN": "Китайский", "en-US": "Английский", "fr-FR": "Французский", "de-DE": "Немецкий",
                "ru-RU": "Русский", "es-ES": "Испанский", "tr-TR": "Tурецкий" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Здравствуйте, я здесь, чтобы помочь!", "Нажмите здесь, чтобы начать чат."],

            // avatar controls translation
            currentInputPlaceholder: "Задай мне вопрос...",

            // drag drop translation
            dragDropText: "Перетащите файл сюда или",
            buttonBrowseText: "Просмотр файлов",
            supportedFormatsText: "Поддерживаемые форматы: jpg, png, txt, pdf.",
            maxFileSizeText: "Максимальный размер файла: 6 МБ.",
            buttonText: "Представлять на рассмотрение",
            attachmentText: "Загрузить вложение",

            // feedback translation
            placeholderText: 'Введите дополнительный отзыв...',
            feedbackText: 'Оставьте дополнительный отзыв',
            harmfulCheckboxText: 'Это вредно/небезопасно',
            helpfulCheckboxText: 'Это бесполезно',
            trueCheckBoxText: 'Это неправда'
        }
    },
    {
        locale: "es-ES",
        typography: {
            // welcome message
            welcome: "¡Hola! ¿Cómo puedo ayudarte?",
            lostNet: "Disculpa, parece que estoy desconectado momentáneamente. Por favor, inténtalo de nuevo más tarde",

            // landing page translation
            loginButton: "Acceso",
            startedButton: "Empezar",
            navigationList: ["Homegits", "Sobre nosotros", "Productos", "Servicios", "Blog", "Ayuda"],
            welcomeHeader: "Donde la innovación se encuentra con la interacción inteligente",
            welcomeDescription: `Estamos apasionadamente comprometidos con elevar el desempeño de nuestros socios. Mejoramos la eficiencia,
             aumentar el valor y desarrollar asociaciones genuinas. Creamos éxito mutuo y defendemos el talento local.
             y las últimas tecnologías.`,
            subscribeButton: "Suscribir",

            // loading translation
            loadingText: "Estoy en una búsqueda para encontrar tu respuesta...",

            // language names translation
            /*languageNames: { "ar-SA": "Arábica", "zh-CN": "Chino", "en-US": "Inglés", "fr-FR": "Francés", "de-DE": "Alemán",
                "ru-RU": "Ruso", "es-ES": "Español", "tr-TR": "Turco" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Hola, estoy aquí para ayudar!", "Haga clic aquí para iniciar el chat."],

            // avatar controls translation
            currentInputPlaceholder: "Hazme una pregunta...",

            // drag drop translation
            dragDropText: "Arrastre y suelte el archivo aquí o",
            buttonBrowseText: "Búsqueda de archivos",
            supportedFormatsText: "Formatos soportados: jpg, png, txt, pdf.",
            maxFileSizeText: "Tamaño máximo de archivo: 6 MB.",
            buttonText: "Entregar",
            attachmentText: "Cargar archivo adjunto",

            // feedback translation
            placeholderText: 'Escriba sus comentarios adicionales...',
            feedbackText: 'Proporcionar comentarios adicionales',
            harmfulCheckboxText: 'Esto es dañino/inseguro',
            helpfulCheckboxText: 'Esto no es útil',
            trueCheckBoxText: 'Esto no es cierto'
        }
    },
    {
        locale: "tr-TR",
        typography: {
            // welcome message
            welcome: "Merhaba! Size nasıl yardımcı olabilirim?",
            lostNet: "Özür dilerim, şu anda bağlantımın kesildiği görünüyor. Lütfen daha sonra tekrar deneyin.",

            // landing page translation
            loginButton: "Giriş yapmak",
            startedButton: "Başlamak",
            navigationList: ["Homegit'ler", "Hakkımızda", "Ürünler", "Hizmetler", "Blog", "Yardım"],
            welcomeHeader: "İnovasyonun Akıllı Etkileşimle Buluştuğu Yer",
            welcomeDescription: `Ortaklarımızın performansını yükseltmeye tutkuyla kararlıyız. Verimliliği artırırız,
             değeri artırın ve gerçek ortaklıklar geliştirin. Karşılıklı başarı yaratırız ve hem yerel yetenekleri destekleriz
             ve en son teknolojiler.`,
            subscribeButton: "Abone",

            // loading translation
            loadingText: "Cevabını bulmak için bir arayıştayım...",

            // language names translation
            /*languageNames: { "ar-SA": "Arapça", "zh-CN": "Çince", "en-US": "İngilizce", "fr-FR": "Fransızca", "de-DE": "Almanca",
                "ru-RU": "Rusça", "es-ES": "İspanyol", "tr-TR": "Türkçe" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Merhaba, yardım etmek için buradayım!", "Sohbeti başlatmak için burayı tıklayın."],

            // avatar controls translation
            currentInputPlaceholder: "Soru sor bana...",

            // drag drop translation
            dragDropText: "Dosyayı buraya sürükleyip bırakın veya",
            buttonBrowseText: "Dosyalara göz atın",
            supportedFormatsText: "Desteklenen formatlar: jpg, png, txt, pdf.",
            maxFileSizeText: "Maksimum dosya boyutu: 6MB.",
            buttonText: "Göndermek",
            attachmentText: "Eki Yükle",

            // feedback translation
            placeholderText: 'Ek geri bildiriminizi yazın...',
            feedbackText: 'Ek geri bildirim sağlayın',
            harmfulCheckboxText: 'Bu zararlı/güvensiz',
            helpfulCheckboxText: 'Bu yararlı değil',
            trueCheckBoxText: 'Bu doğru değil'
        }
    },
    {
        locale: "sr-RS",
        typography: {
            welcome: "Zdravo! Kako mogu da vam pomognem?",
            lostNet: "Izvinite, izgleda da je moja veza prekinuta. Molim Vas pokušajte ponovo kasnije.",

            // landing page translation
            loginButton: "Prijavite se",
            startedButton: "Počnite",
            navigationList: ["Početna", "O nama", "Proizvodi", "Usluge", "Blog", "Pomoć"],
            welcomeHeader: "Mesto gde se inovacija susreće sa inteligentnom interakcijom",
            welcomeDescription: `Strasno smo posvećeni poboljšanju performansi naših partnera. Povećavamo efikasnost,
     povećavamo vrednost i razvijamo prave partnerske odnose. Stvaramo uzajamni uspeh i podržavamo lokalne talente
     i najsavremenije tehnologije.`,
            subscribeButton: "Pretplatite se",

            // loading translation
            loadingText: "Tražim odgovor za vas...",

            // language names translation
            /*languageNames: { "ar-SA": "Arapça", "zh-CN": "Çince", "en-US": "İngilizce", "fr-FR": "Fransızca", "de-DE": "Almanca",
                "ru-RU": "Rusça", "es-ES": "İspanyol", "tr-TR": "Türkçe" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Zdravo, ovde sam da pomognem!", "Kliknite ovde da započnete razgovor."],

            // avatar controls translation
            currentInputPlaceholder: "Postavite mi pitanje...",

            // drag drop translation
            dragDropText: "Prevucite i otpustite datoteku ovde ili",
            buttonBrowseText: "Pregledajte datoteke",
            supportedFormatsText: "Podržani formati: jpg, png, txt, pdf.",
            maxFileSizeText: "Maksimalna veličina datoteke: 6MB.",
            buttonText: "Pošaljite",
            attachmentText: "Učitajte prilog",

            // feedback translation
            placeholderText: 'Napišite dodatne povratne informacije...',
            feedbackText: 'Pružite dodatne povratne informacije',
            harmfulCheckboxText: 'Ovo je štetno/nesigurno',
            helpfulCheckboxText: 'Ovo nije korisno',
            trueCheckBoxText: 'Ovo nije tačno'
        }
    },
    {
        locale: "sr-Cyrl-RS",
        typography: {
            // welcome message
            welcome: "Здраво! Како могу да Вам помогнем?",
            lostNet: "Извините, изгледа да је моја веза прекинута. Молим Вас покушајте поново касније.",

            // landing page translation
            loginButton: "Пријавите се",
            startedButton: "Почните",
            navigationList: ["Почетна", "О нама", "Производи", "Услуге", "Блог", "Помоћ"],
            welcomeHeader: "Место где се иновација сусреће са интелигентном интеракцијом",
            welcomeDescription: `Страсно смо посвећени побољшању перформанси наших партнера. Повећавамо ефикасност,
             повећавамо вредност и развијамо праве партнерске односе. Стварамо узајамни успех и подржавамо локалне таленте
             и најсавременије технологије.`,
            subscribeButton: "Претплатите се",

            // loading translation
            loadingText: "Тражим одговор за Вас...",

            // language names translation
            /*languageNames: { "ar-SA": "Arapça", "zh-CN": "Çince", "en-US": "İngilizce", "fr-FR": "Fransızca", "de-DE": "Almanca",
                "ru-RU": "Rusça", "es-ES": "İspanyol", "tr-TR": "Türkçe" },*/
            languageNames: { "ar-SA": "عربي", "zh-CN": "中國人", "en-US": "English", "fr-FR": "Français",
                "de-DE": "Deutsch", "ru-RU": "Русский", "es-ES": "Español", "tr-TR": "Türkçe", "sr-RS": "Srpski",
                "sr-Cyrl-RS": "Српски"},

            // avatar init translation
            greetingMessages: ["Здраво, овде сам да помогнем!", "Кликните овде да започнете разговор."],

            // avatar controls translation
            currentInputPlaceholder: "Поставите ми питање...",

            // drag drop translation
            dragDropText: "Превуците и отпустите датотеку овде или",
            buttonBrowseText: "Прегледајте датотеке",
            supportedFormatsText: "Подржани формати: jpg, png, txt, pdf.",
            maxFileSizeText: "Максимална величина датотеке: 6MB.",
            buttonText: "Пошаљите",
            attachmentText: "Учитајте прилог",

            // feedback translation
            placeholderText: 'Напишите додатне повратне информације...',
            feedbackText: 'Пружите додатне повратне информације',
            harmfulCheckboxText: 'Ово је штетно/несигурно',
            helpfulCheckboxText: 'Ово није корисно',
            trueCheckBoxText: 'Ово није тачно'
        }
    }
]

